export default {
  data() {
    return {
      tableData: [],
      loading: false,
      params: {
        pageSize: 20,
        pageNo: 1
      },
      tmp_params: {},
      total: 0,
      currentItem: null
    }
  },
  watch: {
    tmp_params: {
      handler(val) {
        try {
          this.getData && this.getData()
        } catch (err) {
          console.log(err)
        } finally {
        }
      },
      deep: true
    }
  },
  created() {
    this.initParams()
  },
  methods: {
    initParams() {
      const _query = this.$route.query

      const query = {}
      Object.keys(_query).forEach(key => {
        if (key.indexOf('query_') !== -1) {
          query[key.replace('query_', '')] = _query[key]
        }
      })

      for (const i in query) {
        query[i] = isNaN(Number(query[i])) ? query[i] : Number(query[i])
      }

      Object.assign(this.params, query)
      this.params = { ...this.params, ...query }
      this.tmp_params = { ...this.params }
    },
    clearParams(fields) {
      if (this.selfReset) {
        this.selfReset()
      }
      const params = { ...this.params }
      if (fields) {
        Object.keys(params).forEach(key => {
          const item = fields.find(field => field.prop === key)

          if (item) {
            // delete params[key]
            params[key] = undefined
          }
        })
      }

      this.params = fields ? params : {}
      this.tmp_params = { ...this.params }
    },
    updateParams(key, val, format = false) {
      const params = { ...this.params }

      params.pageNo = 1
      params[key] = val
      Object.assign(this, { params })
    },
    replaceData() {
      this.params.pageNo = 1
      for (const i in this.params) {
        if (this.params[i] === undefined) delete this.params[i]
      }

      const params = { ...this.params }

      this.tmp_params = params
    }
  }
}
